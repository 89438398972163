/* You can add global styles to this file, and also import other style files */
$gutter: 1rem; //for primeflex grid system
$xl: 1920px;

/* Layout */
@import "assets/layout/styles/layout/layout.scss";

@import "assets/layout/styles/theme/theme-light/gm/theme.css";

/* PrimeNG */
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";


@media screen and (min-width: 1024px) {
  .home-content-wrapper {
    max-width: 960px
  }
}

@media screen and (max-width: 1215px) {
  .home-content-wrapper.is-widescreen:not(.is-max-desktop) {
    max-width: 1152px
  }
}

@media screen and (max-width: 1407px) {
  .home-content-wrapper.is-fullhd:not(.is-max-desktop):not(.is-max-widescreen) {
    max-width: 1344px
  }
}

@media screen and (min-width: 1216px) {
  .home-content-wrapper:not(.is-max-desktop) {
    max-width: 1152px
  }
}

@media screen and (min-width: 1408px) {
  .home-content-wrapper:not(.is-max-desktop):not(.is-max-widescreen) {
    max-width: 1360px
  }
}



@media screen and (min-width: 1960px) {
  .layout-content,
  .landing-wrapper {
    width: unset;
  }

  .layout-topbar {
    width: unset;
  }
}

.prevent-select,
td {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.text-semi-xs {
  font-size: 0.825rem !important;
}

.p-button.p-button-xs {
  font-size: 0.75rem;
  padding: 0.5rem 0.75rem;
}


.p-sidebar a {
  color: #1e293b
}

.filter-label {
  margin-bottom: 0.25rem;
  font-size: 0.875rem;
  font-weight: 600;
  text-transform: uppercase;
}

.p-input-icon-left-right {
  position: relative;
  display: inline-block;
}

.p-input-icon-left-right > .p-inputtext {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.p-input-icon-left-right > i {
  position: absolute;
  top: 50%;
  margin-top: -.5rem;
}

.p-input-icon-left-right > i:first-of-type {
  left: 0.75rem;
  color: #64748b;
}

.p-input-icon-left-right > i:last-of-type {
  right: 0.75rem;
  color: #64748b;
}

.p-dropdown-sm .p-inputtext.p-dropdown-label {
  padding-top: 0.35rem;
  padding-bottom: 0.35rem;
  padding-left: 0.7rem;
  padding-right: 0.7rem;
  font-size: 12px;
}

.p-dropdown-sm .p-dropdown-trigger {
  width: 2rem;
}

.p-multiselect-sm .p-component {
  font-size: 12px;
}

.p-multiselect-sm .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.p-multiselect-sm .p-checkbox,
.p-multiselect-sm .p-checkbox .p-checkbox-box {
  width: 16px;
  height: 16px;
}

.p-multiselect-sm .p-checkbox .p-checkbox-box {
  border-radius: 4px;
}

.p-multiselect-sm .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  padding-top: 0.35rem;
  padding-bottom: 0.35rem;
}

.p-multiselect-sm .p-multiselect-label {
  padding-top: 0.35rem;
  padding-bottom: 0.35rem;
}

.p-multiselect .p-multiselect-label-empty,
.p-multiselect-sm, .p-multiselect-label-empty {
  visibility: visible;
}

.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
  padding: 0.35rem 0.35rem;
  font-size: small;
}

/** Popup Related Style */
.popup-field {
  font-size: small;
  margin-bottom: -1.5rem;
}

.popup-field > div:nth-child(2) {
  font-weight: 600;
}

.popup-field--header {
  font-size: 1.125rem;
  font-weight: 700;
}

.popup-field--footer {
  font-size: x-small;
  font-style: italic;
}

.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: var(--border-radius);
  background: var(--surface-card);
  border: 1px solid var(--surface-border);
  box-shadow: 0px 0px 40px rgba(176, 178, 204, 0.5);
  z-index: 1100;
}

.p-multiselect.stamo-filter-sm .p-component {
  font-size: 12px;
}

.p-multiselect.stamo-filter-sm .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.p-multiselect.stamo-filter-sm .p-checkbox,
.p-multiselect.stamo-filter-sm .p-checkbox .p-checkbox-box {
  width: 16px;
  height: 16px;
}

.p-multiselect.stamo-filter-sm .p-checkbox .p-checkbox-box {
  border-radius: 4px;
}

.p-multiselect.stamo-filter-sm .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  padding-top: 0.35rem;
  padding-bottom: 0.35rem;
}

.p-multiselect.stamo-filter-sm .p-multiselect-label {
  padding-top: 0.35rem;
  padding-bottom: 0.35rem;
}

.p-multiselect .p-multiselect-label-empty,
.p-multiselect.stamo-filter-sm, .p-multiselect-label-empty {
  visibility: visible;
}

.stamo-filter-sm .p-inputtext.p-dropdown-label {
	padding-top: 0.35rem;
	padding-bottom: 0.35rem;
	padding-left: 0.7rem;
	padding-right: 0.7rem;
	font-size: 12px;
}

.stamo-filter-sm .p-dropdown-trigger {
	width: 2rem;
}

.p-multiselect-panel .p-multiselect-header:has(label) {
  justify-content: start;
}

.p-multiselect-panel .p-multiselect-header:has(label) .p-multiselect-close {
  margin-left: auto;
}


/** Chart Related Styles */
.label__box--white {
  fill: #FFFFFF
}

.label__box--lightgray {
  fill: #F5F5F5
}

.label__box--highlighted {
  fill: #f5f2aa;
}

.label__box--selected {
  fill: #8CC4D3
}

.label__box--lightgray + .label__text {
  fill-opacity: 0.8;
}

.label:hover {
  cursor: pointer;
}

.label:hover > .label__box,
.label:hover > .label__box--white {
  fill: #f5f5f5
}

.label:hover > .label__box--selected {
  fill: #8CC4D3
}

.label:hover .label__box--lightgray + .label__text {
  fill-opacity: 1;
}

.label__text {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  opacity: 0.8;
  padding-left: 4px;
  padding-right: 4px;
}

g.bar:focus {
  outline: none;
}

:root {
  --sidebar-padding: 1rem;
  --border-radius: 1rem;
  --side-menu-border-radius: 6px;
  --top-bar-inner-height: 42px;
  --top-bar-height: calc(var(--top-bar-inner-height) + 1rem + 2px);
  --primary-color-hover: #8b6f41;
}

.layout-sidebar .layout-menu .layout-root-menuitem > .layout-menuitem-root-text {
  font-size: 0.857rem;
  text-transform: uppercase;
  font-weight: 700;
  color: var(--root-menuitem-text-color);
  padding: 1.25rem 1rem 0.75rem 1rem;
}

.layout-sidebar .layout-menu {
  border-radius: var(--side-menu-border-radius);
  margin-left: var(--sidebar-padding);
  margin-right: var(--sidebar-padding);
}

.layout-sidebar .layout-menu ul a {
  padding-left: calc(1.75rem - var(--sidebar-padding));
  padding-right: calc(1.75rem - var(--sidebar-padding));
}

.layout-sidebar .layout-menu ul svg {
  width: 20px;
}


.layout-sidebar .layout-menu .layout-root-menuitem > .layout-menuitem-root-text {
  padding-left: calc(1.75rem - var(--sidebar-padding));
}

.layout-sidebar .layout-menu ul ul li li li a {
  padding-left: 0.5rem;
}

.layout-sidebar .layout-menu ul ul li li a {
  padding-left: calc(1.25rem - var(--sidebar-padding));
}

.layout-sidebar .layout-menu ul ul li a {
  padding-left: calc(1.25rem - var(--sidebar-padding));
}

.layout-sidebar .layout-menu ul a {
  padding-left: calc(1.75rem - var(--sidebar-padding)); // 1.5rem original
}

.layout-sidebar .layout-menu ul a.active-route {
  font-weight: 500;
  background-color: var(--primary-color);
  color: white;
  border-radius: var(--side-menu-border-radius);
}

.layout-sidebar .layout-menu ul a.active-route:hover {
  font-weight: 500;
  background-color: var(--primary-color);
  color: white;
  border-radius: var(--side-menu-border-radius);
}

.layout-sidebar .layout-menu ul a:hover {
  background-color: var(--menuitem-hover-bg-color);
  border-radius: var(--side-menu-border-radius);
}

.layout-sidebar .layout-menu ul ul {
  overflow: hidden;
  border-radius: var(--side-menu-border-radius);
}

// Higlight active menu item and its parent with gold background
.layout-sidebar .layout-menu li.active-menuitem > a:has(+ ul a.active-route),
.layout-sidebar .layout-menu a.active-route {
  background-color: #AC8856;
}

// Set the color of active menu item and its parent to white
.layout-sidebar .layout-menu ul a.active-route .layout-menuitem-icon,
.layout-sidebar .layout-menu li.active-menuitem > a:has(+ ul a.active-route) .layout-menuitem-icon,
.layout-sidebar .layout-menu li.active-menuitem > a:has(+ ul a.active-route) .layout-menuitem-text,
.layout-sidebar .layout-menu li.active-menuitem > a:has(+ ul a.active-route) .layout-submenu-toggler {
  color: #FFFFFF;
}

// Show a line at the left of every child menu
.layout-sidebar .layout-menu ul li ul {
  border-left: 1px solid #dbdbdb;
  margin-left: 1rem;
  padding-left: 0.75rem;
  border-radius: 0;
  transition: margin-top 0.3s ease-in-out, margin-bottom 0.3s ease-in-out;
}

// Add vertical space on every child menu
.layout-sidebar .layout-menu ul li.active-menuitem > ul {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

// .layout-sidebar .layout-menu ul li ul li:first-child {
//   margin-top: 0.75rem;
// }

// .layout-sidebar .layout-menu ul li ul li:last-child {
//   margin-bottom: 0.75rem;
// }

.layout-topbar {
  background-color: var(--primary-color);
  border-radius: var(--border-radius);
  padding: 0.4rem;
  margin: 0;
  height: var(--top-bar-inner-height);
}

.layout-content-wrapper {
  // margin-top: var(--top-bar-height);
  height: calc(100vh - var(--top-bar-height));
  // overflow-y: none;
  padding: 0;
  overflow: auto;
}

.layout-content {
  overflow: auto;
  padding: 1.25em 1.25rem 1.25rem;
  position: relative;
}

.layout-sidebar {
  padding-top: calc(var(--top-bar-height));
}

app-topbar {
  position: fixed;
  top: 0;
  left: 0;
  padding: 1rem;
  width: 100%;
  z-index: 1000;
  margin: 0;
  height: var(--top-bar-height);
}

.content-spacer {
  height: var(--top-bar-height);
  width: 100%;
}

.app-logo {
  color: white;
  line-height: 1
}

.topbar-search {
  width: 100%;
  max-width: 20rem;
  border-radius: var(--border-radius);
}

.layout-topbar .topbar-menu li.topbar-search .p-inputtext {
  border-radius: var(--border-radius);
}

.topbar-profile {
  line-height: 1;
  color: white;
}

.layout-topbar .topbar-menu li.topbar-profile img {
  width: calc(var(--top-bar-inner-height) + 0.5rem);
  height: calc(var(--top-bar-inner-height) + 0.5rem);
}

.layout-topbar .topbar-menu li.topbar-profile .p-avatar {
  width: calc(var(--top-bar-inner-height) + 0.5rem);
  height: calc(var(--top-bar-inner-height) + 0.5rem);
}


li.topbar-profile > span {
  padding-right: 0.5rem;
  margin-right: -0.32rem;
  border-radius: calc(var(--top-bar-inner-height) / 2) var(--border-radius) var(--border-radius) calc(var(--top-bar-inner-height) / 2);
  height: var(--top-bar-inner-height);
  margin-left: 1.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

li.topbar-profile > span:hover {
  background-color: var(--primary-color-hover);
}

li.topbar-profile > span:hover {
  background-color: var(--primary-color-hover);
}

li.topbar-profile > span:hover > p-avatar > .p-avatar {
  background-color: #313131 !important;
}

.layout-topbar .topbar-start .topbar-menubutton:hover {
  background-color: var(--primary-color);
}

.layout-topbar .topbar-start .topbar-menubutton:hover {
  background-color: var(--primary-color);
}

.layout-topbar .topbar-start .topbar-menubutton i {
  color: var(--primary-color-text);
}

.layout-topbar .topbar-start .topbar-menubutton:hover {
  background-color: var(--primary-color-hover);
}

.layout-topbar .topbar-start .topbar-menubutton:focus {
  box-shadow: unset;
}

.layout-sidebar .layout-menu ul a {
  transition: background-color var(--transition-duration), color var(--transition-duration), box-shadow var(--transition-duration);
  border-radius: var(--side-menu-border-radius);
}

/*.p-inputtext {
  padding: 0.5rem;
}

.p-multiselect .p-multiselect-label {
  padding: 0.5rem;
}*/

.home-products-card {
  border-radius: var(--border-radius) !important;
}

a:-webkit-any-link {
  color: var(--primary-color);
  cursor: pointer;
}

.layout-topbar .topbar-menu li.topbar-search .p-inputtext {
  padding-top: 0.15rem;
  padding-bottom: 0.15rem;
}


.blurred {
  filter: blur(8px);
}

/**
  * This rule fixes a bug in these charts where the card-heading's margin bottom is not rendered properly
  * causing the empty message to be too close to the card-heading
  */
app-four-comparison-data-grid + app-dashboard-data-empty-message > div,
app-chart-bar-chart-with-line + app-dashboard-data-empty-message > div {
  margin-top: 1rem;
}

// Methodology Table
table.methodology-table {
  width: 100%;
  height: 100%;
  table-layout: fixed;
  font-size: small;
}

table.methodology-table tr {
  height: 40px; /* Set the fixed height for table.methodology-table row */
}

table.methodology-table tbody tr:nth-child(odd) {
  background-color: #fff;
}

table.methodology-table tbody tr:nth-child(even) {
  background-color: #F5F5F5;
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
}

table.methodology-table th,
table.methodology-table td {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

table.methodology-table th,
table.methodology-table td {
  height: 40px;
  overflow: hidden;             /* Hide any content that overflows */
  white-space: nowrap;          /* Allow text to wrap in multiple lines */
  text-overflow: ellipsis;      /* Show ellipsis (...) for overflowing text */
}

// Set the column size for small to medium screens
table.methodology-table .submarket-name-col {
  width: 40%;
}
table.methodology-table .wz-id-col {
  width: 14%;
}
table.methodology-table .wz-name-col {
  width: 30%;
}
table.methodology-table .value-col {
  width: 16%;
}

// Set the column size for large screens
@media screen and (min-width: 1920px) {
  table.methodology-table .submarket-name-col {
    width: 260px;
  }
  table.methodology-table .wz-id-col {
    width: 70px;
  }
  table.methodology-table .wz-name-col {
    max-width: 200px;
  }
  table.methodology-table .value-col {
    width: 145px;
  }
}